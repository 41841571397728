import { useEffect, useState, useContext } from 'react';
import './index.css';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonIcon,
  IonButtons,
  IonButton,
  IonSearchbar,
  IonRow,
  IonGrid,
  IonCol,
  IonCard,
} from '@ionic/react';

import { addCircle } from 'ionicons/icons';

import GroupCard from '../../components/GroupCard';

import CreateGroupModal from './components/create-group-modal';
import { useGetUserGroups, useGroup } from '../../services/use-group';
import { Group, User } from '../../../core/src/public-api';

const Groups: React.FC = () => {
  const { groups } = useGetUserGroups();
  const [showModal, setShowModal] = useState(false);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Groups</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen id="content">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Groups</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(true)}>
                <IonIcon icon={addCircle} size="large"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          {groups.length > 0 ? (
            <IonRow>
              {groups.map((group, index) => (
                <IonCol key={index} size="12" size-sm="4">
                  <GroupCard group={group} />
                </IonCol>
              ))}
            </IonRow>
          ) : (
            <IonRow>
              <IonCol>
                <IonCard className="p-4 mt-2">
                  <p className="font-semibold text-xl">
                    You aren't in any groups yet. Create a group 👇
                  </p>
                </IonCard>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
      {groups.length === 0 && (
        <div className="ion-padding">
          <IonButton expand="block" onClick={() => setShowModal(true)}>
            <IonIcon slot="start" icon="addCircle" color="black">
              {' '}
            </IonIcon>
            Create new group
          </IonButton>
        </div>
      )}
      {/* Modal stuff */}
      <CreateGroupModal
        showModal={showModal}
        setShowModal={(isOpen) => setShowModal(isOpen)}
      />
    </IonPage>
  );
};

export default Groups;
