import { GameDocument, GamePickDocument, TeamDocument } from '..';
import { FirestoreBase } from '.';
import { isBoolean, isUndefined, omitBy } from 'lodash';
export class GamePick extends FirestoreBase implements GamePickDocument {
  game: GameDocument;
  pickedTeam: TeamDocument;
  winningTeamPicked?: boolean;
  constructor(value: GamePick | GamePickDocument) {
    super(value);
    this.game = value.game;
    this.pickedTeam = value.pickedTeam;
    if (isBoolean(value.winningTeamPicked)) {
      this.winningTeamPicked = value.winningTeamPicked;
    }
  }

  build(userId?: string) {
    const pick: any = omitBy(this, isUndefined);
    return {
      ...pick,
      lastUpdatedBy: userId || this.lastUpdatedBy,
      lastUpdatedOn: userId ? new Date() : this.lastUpdatedOn,
    } as any;
  }
}
