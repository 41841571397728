import {
  IonAlert,
  IonButton,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonModal,
  IonCol,
  IonLabel,
  IonButtons,
  IonImg,
  IonItemDivider,
  IonItem,
  IonBackButton,
} from '@ionic/react';

import { StyledIonButton } from '../../../components/MyComponents';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import FormInput from '../../../shared/components/form-input';

import { emailValidator } from '../../../utils/validators/form-validators';
import { useAuth } from '../../services/auth.service';
import GoogleSignInButton from '../../../components/GoogleSignIn';
import { UserContext } from '../../../utils/context';
import { useContext } from 'react';
import { useAuthPath } from '../../hooks/use-auth-path';

const Register: React.FC<{
  navigate: (path: string) => void;
  onDismiss: () => void;
}> = ({ navigate, onDismiss }) => {
  const [showError, setShowError] = useState(false);
  const authPath = useAuthPath();
  const { handleSubmit, control, formState, watch, trigger } = useForm();
  const { isDirty, isValid } = formState;
  const history = useHistory();
  const auth = useAuth();
  const { user } = useContext(UserContext);

  React.useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      await trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const handleLogin = async (event: any) => {
    try {
      await auth.register(event.email, event.password, event.first, event.last);
      onDismiss();
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <IonContent>
      <div className="ion-padding ion-text-center">
        <h1 className={'branded-title'}>GameTheory</h1>
      </div>
      <IonGrid fixed className="ion-padding">
        <GoogleSignInButton onDismiss={() => onDismiss()} />
        <IonItemDivider className="ion-margin" />
        <form
          onSubmit={handleSubmit((data) => handleLogin(data))}
          className="mt-12"
        >
          <FormInput
            label="First name"
            name="first"
            type="text"
            control={control}
            rules={{
              required: { value: true, message: 'First name is required' },
            }}
          />
          <FormInput
            label="Last name"
            name="last"
            type="text"
            control={control}
            rules={{
              required: { value: true, message: 'Last name is required' },
            }}
          />
          <FormInput
            label="Email"
            name="email"
            control={control}
            rules={{
              validate: (value: string) =>
                emailValidator(value) || 'Valid email is required',
              required: { value: true, message: 'Email is required' },
            }}
          />
          <FormInput
            label="Password"
            name="password"
            type="password"
            control={control}
            rules={{
              required: { value: true, message: 'Password is required' },
            }}
          />
        </form>
        <IonButton
          expand="block"
          onClick={handleSubmit((data) => handleLogin(data))}
          disabled={!isValid}
          color="secondary"
        >
          Register
        </IonButton>
        <IonRow className="ion-justify-content-center">
          <IonButton fill="clear" onClick={() => navigate('login')}>
            Don't have an account? Log in
          </IonButton>
        </IonRow>
      </IonGrid>

      <IonAlert
        isOpen={showError}
        onDidDismiss={() => setShowError(false)}
        header={'Error'}
        message={'Unable to send login.  Check your info and try again'}
        buttons={['Ok']}
      />
    </IonContent>
  );
};

export default Register;
