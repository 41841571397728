import {
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from '@ionic/react';
import { group } from 'console';
import { useEffect } from 'react';
import {
  LeaderboardDocument,
  LeaderboardMemberDocument,
} from '../../../core/src/public-api';

const LeaderboardCard: React.FC<{
  leaderboard: LeaderboardDocument;
  handleClick?: () => void;
}> = ({ leaderboard, handleClick }) => {
  return (
    <IonCard
      className="w-max px-6 py-2 relative flex flex-row justify-between"
      onClick={() => handleClick && handleClick()}
    >
      <div className="flex flex-col">
        <IonCardTitle color="primary" className="text-3xl my-2">
          {leaderboard.league}
        </IonCardTitle>
        <IonCardSubtitle>{leaderboard.season}</IonCardSubtitle>
      </div>
    </IonCard>
  );
};

export default LeaderboardCard;
