import styled, { keyframes } from 'styled-components';
import {
  IonTitle,
  IonLabel,
  IonRow,
  IonCol,
  IonProgressBar,
  IonCardTitle,
  IonThumbnail,
  IonChip,
  IonIcon,
  IonContent,
  IonButton,
} from '@ionic/react';

export const MyIonTitle = styled(IonTitle)`
  font-weight: bold;
  letter-spacing: 1px;
`;

export const MyIonLabel = styled(IonLabel)`
  color: #888;
`;

export const MyFilterRow = styled(IonRow)`
  padding: 10px 0 0 10px;
`;

interface TeamCardProps {
  gradientColors: {
    top: string;
    bottom: string;
  };
  picked: boolean;
}

const animateTeamGradient = keyframes`
 0% { background-position: 0% 50%; }
 50% { background-position: 100% 50%; }
 100% { background-position: 0% 50%; }
`;

export const TeamCard = styled(IonCol)<TeamCardProps>`
  border-radius: 10px;
  background: ${(p) =>
    p.picked
      ? `linear-gradient(206deg,${p.gradientColors?.top} 0%,${p.gradientColors?.bottom} 100%) !important`
      : `linear-gradient(206deg, rgba(0, 0, 0, 0.3) 0%,rgba(0, 0, 0, 3) 100%)`};
  background-size: 100% 100% !important;
  transition: all 150ms ease-in-out;
  padding: 12px 3px;
  transition: transform 150ms ease-in-out;
  animation-name: ${animateTeamGradient};
  animation-timing-function: ease;
  animation-duration: 15s;
  animation-iteration-count: infinite;
`;

export const GameTime = styled(IonCardTitle)`
  white-space: nowrap;
  font-weight: bold;
  margin: 5px 0;
  color: var(--ion-color-step-750);
  font-size: 24px;
`;

export const MyIonProgressBar = styled(IonProgressBar)`
  margin: 0 auto;
  border-radius: 20px;
  height: 5px;
`;

export const ProgressBarContainer = styled.div`
  max-width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const MyTeamThumbnailCentered = styled(IonThumbnail)`
  margin: 0 auto;
`;

export const MyFilterChip = styled(IonChip)`
  background: ${(props) =>
    props.color == 'selected'
      ? 'var(--ion-color-primary)'
      : 'var(--ion-color-primary-contrast)'};
  color: ${(props) =>
    props.color == 'selected'
      ? 'var(--ion-color-primary-contrast)'
      : 'var(--ion-color-primary)'};
  border: 1px solid var(--ion-color-primary);
`;

export const MyTeamChip = styled(IonChip)`
  margin-top: 5px;
  background: ${(props) =>
    props.color == 'picked'
      ? 'var(--ion-color-primary)'
      : 'var(ion-color-primary-tint)'};
  color: ${(props) =>
    props.color == 'picked'
      ? 'var(--ion-color-primary-contrast)'
      : 'var(--ion-color-primary-shade)'};
`;

export const MyCardActionIcon = styled(IonIcon)`
  height: 24px;
  width: 24px;
  transition: all 150ms ease-in-out;
  :hover {
    color: var(--ion-color-primary);
    cursor: pointer;
  }
`;

export const MyRowSpaceAround = styled(IonRow)`
  justify-content: space-around;
`;

export const RowSpaceBetween = styled(IonRow)`
  justify-content: space-between;
`;
export const MyContentVerticalCenter = styled(IonContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledIonButton = styled(IonButton)`
  border-radius: 20px;
`;

export const fullWidthDiv = styled.div`
  width: 100%;
`;

export const animationContainer = styled.div`
  width: 300px;
  height: 300px;
`;

export const SplashScreen = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 1000;
`;
