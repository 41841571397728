import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LeaderboardDocument } from '../../../core/src/models';
import { useLeaderboard } from '../../services/use-leaderboard';
import LeaderboardCardWithMembers from '../../components/leaderboard-card-with-members';

export const Leaderboards: React.FC = () => {
  const [leaderboards, setLeaderboards] = useState<LeaderboardDocument[]>([]);
  const { getLeaderboards } = useLeaderboard();
  const history = useHistory();
  useEffect(() => {
    getLeaderboards()
      .then((leaderboards) => {
        console.log('leaderboards?', leaderboards);
        setLeaderboards(leaderboards);
      })
      .catch((error) => console.error(error));
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Leaderboards</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Leaderboards</IonTitle>
          </IonToolbar>
        </IonHeader>
        <section id="leaderboards">
          {leaderboards.map((leaderboard, index) => (
            <LeaderboardCardWithMembers
              handleClick={() =>
                history.push(`/leaderboards/${leaderboard.id}`)
              }
              key={index}
              leaderboard={leaderboard}
            />
          ))}
        </section>
      </IonContent>
    </IonPage>
  );
};

export default Leaderboards;
