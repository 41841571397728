import firebase from 'firebase';
import { useContext, useEffect, useState } from 'react';
import {
  Game,
  GameDocument,
  GamePick,
  GamePickDocument,
  TeamDocument,
} from '../../core/src/models';
import { generateNewFsDoc, splitGamesByWeek } from '../../core/src/utils';
import { UserContext } from '../utils/context';
import {
  convertFirestoreTimestamps,
  firestoreData,
} from '../utils/firestore-data';
import { createId } from '../utils/firestore/generate-firestore-id';
import {
  useCollection,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import { getAllGames } from './game-service';

export const useGamePick = () => {
  const { user } = useContext(UserContext);
  const firestoreRef = firebase
    .firestore()
    .collection('user')
    .doc(user?.id)
    .collection('gamePick');

  const createGamePick = async (team: TeamDocument, game: Game) => {
    const id = createId();
    const fsDoc = generateNewFsDoc(user?.id as string, id);
    const gamePickDoc: GamePickDocument = {
      ...fsDoc,
      game: game.build(),
      pickedTeam: team,
    };
    const gamePick = new GamePick(gamePickDoc);
    return firestoreRef.doc(gamePick.id).set(gamePick.build());
  };

  const updateGamePick = async (gamePick: GamePick, team: TeamDocument) => {
    if (gamePick.pickedTeam.id === team.id) {
      return firestoreRef.doc(gamePick.id).delete();
    } else {
      return firestoreRef.doc(gamePick.id).set({
        ...gamePick.build(),
        pickedTeam: team,
      });
    }
  };

  const getGamePicks = async () => {
    const snaps = await firestoreRef.get();
    return firestoreData(snaps).map(
      (gamePickDoc: GamePickDocument) => new GamePick(gamePickDoc)
    );
  };

  return {
    createGamePick,
    updateGamePick,
    getGamePicks,
  };
};
