import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LeaderboardDocument } from '../../../../../core/src/models';
import LeaderboardCard from '../../../../components/leaderboard-card';
import { useGroup } from '../../../../services/use-group';
import {
  useLeaderboard,
  useLeaderboardListener,
} from '../../../../services/use-leaderboard';

const AddLeaderboardModal: React.FC<{
  showModal: boolean;
  setShowModal: (isOpen: boolean) => void;
}> = ({ showModal, setShowModal }) => {
  useEffect(() => {
    getLeaderboards()
      .then((leaderboards) => {
        setLeaderboards(leaderboards);
      })
      .catch((error) => console.error(error));
  }, []);

  const [leaderboards, setLeaderboards] = useState<LeaderboardDocument[]>([]);
  const { getLeaderboards } = useLeaderboard();
  const { addLeaderboardToGroup } = useGroup();
  const { id } = useParams() as any;

  const addLeaderboard = async (leaderboard: LeaderboardDocument) => {
    try {
      await addLeaderboardToGroup(leaderboard, id);
      setShowModal(false);
    } catch (error) {
      console.error('Unable to add leaderboard', error);
    }
  };
  return (
    <IonModal isOpen={showModal} swipeToClose={true}>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>Add Leaderboard</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(false)}>
              <IonIcon icon="close">Close</IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          {leaderboards.map((leaderboard, index) => (
            <LeaderboardCard
              handleClick={() => addLeaderboard(leaderboard)}
              key={index}
              leaderboard={leaderboard}
            />
          ))}
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default AddLeaderboardModal;
