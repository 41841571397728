import { User } from '.';
import { convertArrayToFirestoreMap, handleModelArray } from '../../utils';
import { GroupDocument } from '../dto/group-document';
import { Visibility } from '../enums/visibility';
import { FirestoreBase } from './firestore-base';

export class Group extends FirestoreBase {
  name: string;
  members: User[];
  visibility: Visibility;

  constructor(value: Group | GroupDocument) {
    super(value);
    this.name = value.name;
    this.members = handleModelArray(value.members).map(
      (user) => new User(user)
    );
    this.visibility = value.visibility;
  }

  addUser(user: User) {
    this.members = [...this.members, user];
  }

  build(userId?: string) {
    return {
      ...this,
      lastUpdatedBy: userId || this.lastUpdatedBy,
      lastUpdatedOn: userId ? new Date() : this.lastUpdatedOn,
      members: convertArrayToFirestoreMap(
        this.members.map((user) => user.build())
      ),
    };
  }
}
