import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import FormInput from '../../../shared/components/form-input';
import { useAuth } from '../../services/auth.service';
import { useForm } from 'react-hook-form';
import { emailValidator } from '../../../utils/validators/form-validators';
import React from 'react';

const ForgotPassword: React.FC<{
  navigate: (path: string) => void;
  onDismiss: () => void;
}> = ({ navigate, onDismiss }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const { handleSubmit, control, formState, watch, trigger } = useForm();
  const { isDirty, isValid } = formState;
  const { url } = useRouteMatch();
  const location = useLocation();
  const auth = useAuth();

  const handleForgotPassword = async (event: any) => {
    try {
      await auth.sendResetPasswordEmail(event.email);
      setShowSuccess(true);
    } catch (error) {
      console.error(error);
      setShowError(true);
    }
  };
  React.useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      await trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);
  const history = useHistory();
  return (
    <IonContent className={'ion-padding'}>
      <IonGrid fixed>
        <IonRow className="ion-justify-content-center ion-margin">
          <IonLabel color="warning">
            Enter your email to reset your password
          </IonLabel>
        </IonRow>
        <form onSubmit={handleSubmit((data) => handleForgotPassword(data))}>
          <FormInput
            control={control}
            name="email"
            label="Email"
            rules={{
              validate: (value: string) =>
                emailValidator(value) || 'Valid email is required',
              required: { value: true, message: 'Email is required' },
            }}
          />
          <IonRow className="ion-justify-content-center">
            <IonButton
              onClick={handleSubmit((data) => handleForgotPassword(data))}
              disabled={!isValid}
            >
              Reset Password
            </IonButton>
          </IonRow>
        </form>
      </IonGrid>

      <IonAlert
        isOpen={showSuccess}
        onDidDismiss={() => {
          setShowSuccess(false);
          history.push('/auth/login');
        }}
        header={'Email Sent'}
        message={'Check your email to reset your password'}
        buttons={['Ok']}
      />

      <IonAlert
        isOpen={showError}
        onDidDismiss={() => setShowError(false)}
        header={'Error'}
        message={'Unable to send email.  Check your info and try again'}
        buttons={['Ok']}
      />
    </IonContent>
  );
};

export default ForgotPassword;
