import React, { useRef } from 'react';
import './index.css';
import { useLeaderboardListener } from '../../../services/use-leaderboard';
import { useParams } from 'react-router-dom';
import LeaderboardPage from '../../../components/leaderboard-page';

const SingleLeaderboard: React.FC = () => {
  const element = useRef<HTMLDivElement>(null);
  const { id } = useParams() as any;

  const { leaderboard, members } = useLeaderboardListener(id);
  return (
    (leaderboard && (
      <LeaderboardPage leaderboard={leaderboard} members={members} />
    )) || <div></div>
  );
};

export default SingleLeaderboard;
