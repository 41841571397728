import { IonButton } from '@ionic/react';
import React, { useRef, useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';

const AvatarCropper: React.FC<{
  initialImage: string;
  handleNewImage: (image: string) => void;
}> = ({ initialImage = '', handleNewImage }) => {
  //Controls for the ReactAvatarEditor
  const [width, setWidth] = useState<number>(200);
  const [height, setHeight] = useState<number>(200);
  const [scale, setScale] = useState<number>(1.0);
  const [allowZoomOut, setAllowZoomOut] = useState<boolean>(false);
  const [rotate, setRotate] = useState<number>(0.0);
  const [borderRadius, setBoarderRadius] = useState<number>(50);
  const [position, setPosition] = useState<any>({ x: 0.5, y: 0.5 });
  const [preview, setPreview] = useState<any>(null);
  const [image, setImage] = useState<string>(initialImage);
  const [imageURL, setImageURL] = useState<string>('');
  const [tempImage, setTempImage] = useState<string>('');
  const [showPreview, setShowPreview] = useState(false);

  //Set the ref to get the resulting image
  const editorRef: React.MutableRefObject<any> = useRef(null);

  //Methods for the ReactAvatarEditor
  const handlePositionChange = (position: any) => {
    setPosition(position);
  };

  const handleImageChange = (e: any) => {
    setImage(e.target.files[0]);
    if (showPreview) setShowPreview(false);
  };

  const handleScale = (e: any) => {
    setScale(parseFloat(e.target.value));
  };

  const onCrop = () => {
    if (editorRef) {
      const url = editorRef.current.getImageScaledToCanvas().toDataURL();
      setImage(url);
      handleNewImage(url);
    }
  };

  return (
    <div className="p-8">
      {!showPreview ? (
        <div>
          <ReactAvatarEditor
            ref={editorRef}
            scale={scale}
            width={width}
            height={height}
            position={position}
            onPositionChange={handlePositionChange}
            rotate={rotate}
            borderRadius={width / (100 / borderRadius)}
            image={image}
            className="editor-canvas rounded-md"
          />
        </div>
      ) : (
        <div>
          <img
            src={tempImage}
            width="200"
            height="200"
            className="rounded-full motion-safe:animate-spin mx-auto"
          />
        </div>
      )}
      <div className="w-4/5 flex flex-row justify-center mx-auto mt-4">
        <input
          name="scale"
          type="range"
          onChange={handleScale}
          min={allowZoomOut ? '0.1' : '1'}
          max="2"
          step="0.01"
          defaultValue="1"
          className="w-4/5"
        />
      </div>
      <div className="mt-8 flex flex-row justify-center">
        <label className=" shadow-lg px-4 py-2 rounded-md w-full cursor-pointer bg-black hover:text-white text-white text-center ease-linear transition-all duration-150 hover:ring-2 ring-yellow-400">
          <span className="mt-2 text-base leading-normal font-semibold">
            Select new photo
          </span>
          <input
            name="newImage"
            type="file"
            onChange={handleImageChange}
            className="hidden"
          />
        </label>
      </div>

      <div className="mt-2 w-full flex flex-row justify-center mx-auto">
        <IonButton
          expand="block"
          onClick={onCrop}
          className="w-full mx-auto inline-flex items-center px-3 py-2 border border-transparent leading-4 font-semibold rounded-md shadow-sm text-black bg-yellow-400"
        >
          Crop & Save
        </IonButton>
      </div>
    </div>
  );
};

export default AvatarCropper;
