import {
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from '@ionic/react';

import { LeaderboardDocument } from '../../../core/src/public-api';

const LeaderboardCardWithMembers: React.FC<{
  leaderboard: LeaderboardDocument;
  handleClick?: () => void;
}> = ({ leaderboard, handleClick }) => {
  //Iterate over the members object and grab 5 photos to display on the leaderboard card
  const getFiveMemberPhotos = () => {
    if (leaderboard) {
      const fiveMemberPhotos = Object.values(leaderboard.members).reduce(
        (acc, v) => {
          if (v.photoUrl) {
            acc.push(v.photoUrl);
          }
          return acc;
        },
        [] as string[]
      );
      return fiveMemberPhotos.slice(0, 5);
    }
  };

  return (
    <IonCard
      className="px-4 py-2 relative flex flex-row justify-between"
      onClick={() => handleClick && handleClick()}
    >
      <div className="flex flex-col">
        <IonCardTitle color="primary" className="text-3xl my-2">
          {leaderboard.league}
        </IonCardTitle>
        <IonCardSubtitle>{leaderboard.season}</IonCardSubtitle>
      </div>
      <div className="flex flex-col justify-between pl-8 py-2 items-end">
        <div className="bg-yellow-400 font-semibold text-black px-2 rounded-md mb-4">
          Top 25
        </div>
        <div className="flex -space-x-2 overflow-hidden">
          {getFiveMemberPhotos().map((photoUrl: string | null, idx: number) =>
            photoUrl ? (
              <img
                key={idx}
                className="inline-block h-8 w-8 rounded-full ring-2 ring-gray-800"
                src={photoUrl}
                alt="member"
              />
            ) : (
              <img
                key={idx}
                className="inline-block h-8 w-8 rounded-full ring-2 ring-gray-800"
                src={'./defaultAvatar.png'}
                alt="member"
              />
            )
          )}
        </div>
      </div>
    </IonCard>
  );
};

export default LeaderboardCardWithMembers;
