import firebase from 'firebase';
import { useContext, useState, useEffect } from 'react';
import {
  GamePick,
  Game,
  GameDocument,
  GamePickDocument,
} from '../../core/src/models';
import { splitGamesByWeek } from '../../core/src/utils';
import { UserContext } from '../utils/context';
import { firestoreData } from '../utils/firestore-data';

export const useGameWeeks = () => {
  const { user } = useContext(UserContext);
  const [weeks, setWeeks] = useState<any[]>([]);
  const [games, setGames] = useState<Game[] | null>(null);
  const [gamePicks, setGamePicks] = useState<GamePick[] | null>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      const unsubscribe = firebase
        .firestore()
        .collection('user')
        .doc(user?.id)
        .collection('gamePick')
        .onSnapshot({
          next: (snaps) => {
            const data = firestoreData(snaps).map(
              (doc: GamePickDocument) => new GamePick(doc)
            );
            setGamePicks(data);
          },
        });
      return unsubscribe;
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('game')
      .orderBy('date')
      .onSnapshot({
        next: (snaps) => {
          const data = firestoreData(snaps).map(
            (doc: GameDocument) => new Game(doc)
          );
          setGames(data);
        },
      });
    return unsubscribe;
  }, []);
  useEffect(() => {
    if (games && gamePicks) {
      const allWeeks = splitGamesByWeek(games, gamePicks as GamePick[]);
      setWeeks(allWeeks);
      setLoading(false);
    }
  }, [JSON.stringify(games), JSON.stringify(gamePicks)]);

  return { weeks, loading };
};
