import { UserDocument } from '../dto/user-document';
import { UserRole, AuthMethod } from '../enums';
import { FirestoreBase } from './firestore-base';

export class User extends FirestoreBase implements UserDocument {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  authMethod: AuthMethod;
  photoUrl?: string | null;
  emailWeeklyReport?: boolean | null;
  lastEmailedDate?: Date | null;
  constructor(value: UserDocument | User) {
    super(value);
    this.firstName = value.firstName;
    this.lastName = value.lastName;
    this.email = value.email;
    this.role = value.role;
    this.authMethod = value.authMethod;
    this.photoUrl = value.photoUrl || null;
    this.emailWeeklyReport = value.emailWeeklyReport || null;
    this.lastEmailedDate = value.lastEmailedDate || null;
  }
}
