import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonButtons,
  IonButton,
  IonIcon,
  useIonViewDidLeave,
  useIonViewDidEnter,
  IonModal,
  useIonModal,
  IonThumbnail,
  IonCol,
  IonItem,
  IonLabel,
  IonFooter,
} from '@ionic/react';

import { cog } from 'ionicons/icons';
import './index.css';
import styled from 'styled-components';
import { version } from '../../../package.json';

import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../utils/context';
import AuthModal from '../../auth';
import { useAuth } from '../../auth/services/auth.service';

const Profile = () => {
  const { user, authUser } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const auth = useAuth();
  const history = useHistory();

  const handleShowModal = (shouldShow: boolean) => {
    setShowModal(shouldShow);
  };

  const handleRedirect = (path: string) => {
    history.push(`${path}`);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profile</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => handleRedirect('/profile/settings')}>
              <IonIcon icon={cog}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Profile</IonTitle>
          </IonToolbar>
        </IonHeader>
        {user && (
          <IonItem
            lines="none"
            className="ion-margin-top"
            onClick={() => handleRedirect('/profile/settings')}
          >
            <IonThumbnail>
              {user?.photoUrl ? (
                <img src={user?.photoUrl} className="rounded-full" />
              ) : authUser?.photoURL ? (
                <img src={authUser?.photoURL} className="rounded-full" />
              ) : (
                <img src={'/defaultAvatar.png'} className="rounded-full" />
              )}
            </IonThumbnail>
            <IonLabel className={'ion-margin-start'}>
              <h1>
                {user?.firstName} {user?.lastName}
              </h1>
              <p>{user?.email}</p>
            </IonLabel>
          </IonItem>
        )}
      </IonContent>
      {/* <IonFooter translucent={true} mode={'ios'} className={'ion-no-border'}>
        <IonToolbar className={'bg-none'}></IonToolbar>
      </IonFooter> */}
      {!user && (
        <IonButton
          className={'ion-margin'}
          expand="block"
          onClick={() => setShowModal(true)}
        >
          Sign In
        </IonButton>
      )}
      <AuthModal onDismiss={handleShowModal} showModal={showModal} />
    </IonPage>
  );
};

export default Profile;
