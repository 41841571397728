import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonGrid,
  IonButtons,
  IonBackButton,
  IonItem,
  IonLabel,
  IonButton,
  IonList,
  IonListHeader,
  IonThumbnail,
  IonCol,
  IonIcon,
  IonModal,
  IonToggle,
  useIonToast,
  IonFooter,
} from '@ionic/react';
import AvatarCropper from '../../components/AvatarCropper';
import { UserContext } from '../../utils/context';
import { useAuth, useUserData } from '../../auth/services/auth.service';
import ItemInputHybrid from '../../components/account-input-item';
import { version } from '../../../package.json';

const AccountSettings: React.FC = () => {
  const auth = useAuth();
  const { user, authUser } = useContext(UserContext);
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [checked, setChecked] = useState(false);

  const [present, dismiss] = useIonToast();


  useEffect(() => {
    if (user) {
      setChecked(user.emailWeeklyReport ? user.emailWeeklyReport : false);
    }
  }, [user]);

  const handleChecked = async (checked: boolean) => {
    setChecked(checked);
    if (user) {
      await handleUserUpdate("emailWeeklyReport", Boolean(checked));
    }
  }

  const handleUserUpdate = async (key: string, value: string|boolean) => {
    if (user) {
      try {
        await auth.updateUser(user.id, { [key]: value });
      } catch (error) {
        setShowError(true);
      }
    }
  };

  const handleNewImage = (image: string) => {
    setShowModal(false);
    setUploading(true);
    handleUpload(image);
  };

  const handleUpload = async (image: string) => {
    try {
      if (user) {
        const upload = await auth.updateUserPhoto(user.id, image);
      }
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: '✅  Your changes are saved',
        duration: 3000,
      });
    } catch (error) {
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: '🛑  Error uploading photo',
        duration: 3000,
      });
      console.error(error);
    }
    setUploading(false);
  };

  const history = useHistory();
  const handleSignOut = () => {
    auth.logout();
    history.push('/profile');
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/profile" />
          </IonButtons>
          <div slot="end" className="ion-padding-end">
            v{version}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonListHeader>
          <IonLabel>Account</IonLabel>
        </IonListHeader>
        <div className={`px-6 my-4  ${uploading && 'animate-pulse'}`}>
          <IonThumbnail
            className="h-32 w-32 relative mx-auto"
            onClick={() => setShowModal(true)}
          >
            <div className="absolute bottom-0 right-0 left-0 top-0 rounded-full flex justify-center items-center z-10 bg-black opacity-50 cursor-pointer">
              <span>Edit</span>
            </div>
            {user?.photoUrl ? (
              <img src={user.photoUrl} className="rounded-full z-1" />
            ) : authUser?.photoURL ? (
              <img src={authUser.photoURL} className="rounded-full z-1" />
            ) : (
              <img src={'/defaultAvatar.png'} className="rounded-full z-1" />
            )}
          </IonThumbnail>
        </div>
        <IonList>
          {user && (
            <ItemInputHybrid
              label={'First name'}
              text={user.firstName}
              handleChange={(firstName: string) =>
                handleUserUpdate('firstName', firstName)
              }
            />
          )}
          {user && (
            <ItemInputHybrid
              label={'Last name'}
              text={user.lastName}
              handleChange={(lastName: string) =>
                handleUserUpdate('lastName', lastName)
              }
            />
          )}
          <IonItem>
            {user?.authMethod === 'email' && (
              <IonButtons slot="end">
                <IonButton>Edit</IonButton>
              </IonButtons>
            )}
            <IonLabel>
              <p>Email</p>
              <h2>{user?.email}</h2>
            </IonLabel>
          </IonItem>
          {user?.authMethod === 'email' && (
            <IonItem>
              <IonButtons slot="end">
                <IonButton>Edit</IonButton>
              </IonButtons>
              <IonLabel>
                <p>Password</p>
                <h2>**********</h2>
              </IonLabel>
            </IonItem>

            )}
          {user && (
          <IonItem className="flex space-between items-center py-4 pr-2">
            <IonLabel>Email me Weekly Reports</IonLabel>
              <IonToggle
              onIonChange={(e) => { handleChecked(e.detail.checked) }}
              checked={Boolean(user.emailWeeklyReport? checked : false)}
              name="emailWeeklyReport"
            />
          </IonItem>
          )}
        </IonList>
      </IonContent>
      <IonFooter translucent={true} mode={'ios'} className={'ion-no-border'}>
        <IonToolbar>
          <IonButton
            className={'ion-margin'}
            expand="block"
            onClick={() => handleSignOut()}
          >
            Sign Out
          </IonButton>
        </IonToolbar>
      </IonFooter>
      <IonModal isOpen={showModal} swipeToClose={true}>
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonTitle>Change Profile Picture</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowModal(false)}>
                <IonIcon icon="close">Close</IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol>
                {user?.photoUrl ? (
                  <AvatarCropper
                    initialImage={user.photoUrl}
                    handleNewImage={handleNewImage}
                  />
                ) : authUser?.photoURL ? (
                  <AvatarCropper
                    initialImage={authUser.photoURL}
                    handleNewImage={handleNewImage}
                  />
                ) : (
                  <AvatarCropper
                    initialImage={'/defaultAvatar.png'}
                    handleNewImage={handleNewImage}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default AccountSettings;
