import firebase from 'firebase';

type snapType =
  | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  | firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;

/**
 * Allows user to pass in any snapshot received from a firestore query and
 * returns the data
 *
 * @param items items
 */
export const firestoreData = (data: snapType): any | any[] | undefined => {
  let dataObj;
  if ((data as any).exists) {
    dataObj = (data as any).data();
  }
  if ((data as any).payload) {
    if ((data as any).payload.exists) {
      dataObj = (data as any).payload.data();
    }
  }
  if ((data as any).docs) {
    if ((data as any).empty) {
      dataObj = [];
    } else {
      dataObj = (data as any).docs.map((item: any) => item.data());
    }
  }
  return dataObj ? convertFirestoreTimestamps(dataObj) : undefined;
};

export const convertFirestoreTimestamps = (item: any): any => {
  if (Array.isArray(item)) {
    return item.map((singleItem) => convertFirestoreTimestamps(singleItem));
  }
  return Object.entries(item).reduce((acc: any, [key, value]) => {
    if (value instanceof firebase.firestore.Timestamp) {
      acc[key] = value.toDate();
    } else if (Array.isArray(value)) {
      acc[key] = value.map((valueArrayItem) =>
        convertFirestoreTimestamps(valueArrayItem)
      );
    } else if (value instanceof Object && value.constructor === Object) {
      acc[key] = convertFirestoreTimestamps(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};
