import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import firebase from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'tailwindcss/tailwind.css';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  personCircleOutline,
  calendarOutline,
  trophy,
  people,
} from 'ionicons/icons';
import Games from './pages/Games';
import Leaderboards from './pages/Leaderboards';
import Profile from './pages/Profile';
import AccountSettings from './pages/Settings';
import Groups from './pages/Groups';
import ManageGroup from './pages/Groups/ManageGroup';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* swiper css files */
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
/* Theme variables */
import './theme/variables.css';
import './styles/global.css';
import Auth from './auth';
import { UserContext } from './utils/context';
import { useUserData } from './auth/services/auth.service';
import { GroupLeaderboard } from './pages/Groups/group-leaderboard';
import SingleLeaderboard from './pages/Leaderboards/single-leaderboard';
const App: React.FC = () => {
  const userData = useUserData();

  return (
    <UserContext.Provider value={userData}>
      <IonApp>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/games">
                <Games />
              </Route>
              <Route exact path="/leaderboards">
                <Leaderboards />
              </Route>
              <Route path="/leaderboards/:id">
                <SingleLeaderboard />
              </Route>
              <Route exact path="/profile">
                <Profile />
              </Route>
              <Route exact path="/">
                <Redirect to="/games" />
              </Route>
              <Route path="/profile/settings">
                <AccountSettings />
              </Route>
              <Route exact path="/groups">
                <Groups />
              </Route>
              <Route path="/groups/:groupId/leaderboard/:leaderboardId">
                <GroupLeaderboard />
              </Route>
              <Route exact path="/groups/:id">
                <ManageGroup />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom" className="padding-top-small">
              <IonTabButton tab="games" href="/games">
                <IonIcon icon={calendarOutline} />
                <IonLabel>Games</IonLabel>
              </IonTabButton>
              <IonTabButton tab="leaderboards" href="/leaderboards">
                <IonIcon icon={trophy} />
                <IonLabel>Leaderboards</IonLabel>
              </IonTabButton>
              <IonTabButton tab="groups" href="/groups">
                <IonIcon icon={people} />
                <IonLabel>Groups</IonLabel>
              </IonTabButton>
              <IonTabButton tab="profile" href="/profile">
                <IonIcon icon={personCircleOutline} />
                <IonLabel>Profile</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    </UserContext.Provider>
  );
};

export default App;
