import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import '../../styles/utilities.css';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSlides,
  IonSlide,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';

import GameCard from '../../components/game-card';

import { Game, TeamDocument } from '../../../core/src/public-api';
import { UserContext } from '../../utils/context';
import AuthModal from '../../auth';
import { useGamePick } from '../../services/use-game-pick';
import { useGameWeeks } from '../../services/use-game-weeks';

const Games: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [pickLoading, setPickLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { weeks, loading } = useGameWeeks();
  const gamePick = useGamePick();
  //TODO: Set the current NFL week to show up first using the date-fns isSameWeek (or something)
  const [weekInView, setWeekInView] = useState('17');

  //Currently the ref is only created on initial load so the activeSegmentRef.current will
  //always be the segment with the value of the initial weekInView set above on line 67.
  //TODO: Update the ref so that it changes with weekInView.
  useEffect(() => {
    if (activeSegmentRef.current) {
      activeSegmentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [weekInView]);

  const handleShowModal = (shouldShow: boolean) => {
    setShowModal(shouldShow);
  };

  const slideOpts = {
    initialSlide: weekInView,
  };

  // a ref variable to handle the current slider
  const slider = useRef<HTMLIonSlidesElement>(null);
  const content = useRef<any>(null);
  const activeSegmentRef = useRef<any>(null);

  // a function to handle the segment changes
  const handleSegmentChange = (e: any) => {
    //setWeekInView(e.detail.value);
    slider.current?.slideTo(e.detail.value);
  };

  const handleSlideChange = async (event: any) => {
    // content.current?.scrollToTop(500);
    const index = await event.target.getActiveIndex();
    setWeekInView(index);
  };

  const handlePick = async (team: TeamDocument, game: Game) => {
    try {
      if (!user) {
        setShowModal(true);
      } else {
        setPickLoading(true);
        if (game.gamePick) {
          await gamePick.updateGamePick(game.gamePick, team);
        } else {
          await gamePick.createGamePick(team, game);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPickLoading(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>NFL Games</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen id="content" ref={content}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">NFL Games</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className={'ion-padding'}>
          <IonSegment
            scrollable
            value={weekInView}
            onIonChange={(e) => handleSegmentChange(e)}
            className={'bg-none'}
          >
            {weeks.map((week: Game[], idx: number) => (
              <IonSegmentButton
                value={idx.toString()}
                key={idx}
                className={'weeks-button'}
                ref={idx === parseInt(weekInView) ? activeSegmentRef : null}
              >
                <IonLabel>Week {idx + 1}</IonLabel>
              </IonSegmentButton>
            ))}
          </IonSegment>
        </div>
        {(weeks.length && (
          <IonSlides
            options={slideOpts}
            className={'ion-padding-top'}
            onIonSlideWillChange={(e) => handleSlideChange(e)}
            ref={slider}
          >
            {weeks.map((week: Game[], idx: number) => {
              return (
                <IonSlide key={idx}>
                  <IonGrid className={'full-width ion-flex'}>
                    {idx === parseInt(weekInView) ? (
                      <IonRow>
                        {week.map((game: Game, index: number) => (
                          <IonCol
                            size="12"
                            size-sm="4"
                            className={'no-margin'}
                            key={index}
                          >
                            <GameCard
                              game={game}
                              handlePick={(team: TeamDocument) =>
                                handlePick(team, game)
                              }
                              loading={pickLoading}
                            />
                          </IonCol>
                        ))}
                      </IonRow>
                    ) : (
                      <></>
                    )}
                    <IonRow className="ion-justify-content-center">
                      <h5 color="grey">All picks are autosaved</h5>
                    </IonRow>
                  </IonGrid>
                </IonSlide>
              );
            })}
          </IonSlides>
        )) ||
          null}
      </IonContent>
      <AuthModal onDismiss={handleShowModal} showModal={showModal} />
    </IonPage>
  );
};

export default Games;
