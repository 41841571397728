import React, { useEffect, useState } from 'react';
import './game-card.css';
import {
  IonLabel,
  IonRow,
  IonGrid,
  IonCard,
  IonCol,
  useIonToast,
} from '@ionic/react';
import styled from 'styled-components';

import { MyTeamThumbnailCentered, GameTime, TeamCard } from '../MyComponents';

import { format } from 'date-fns-tz';
import { differenceInMinutes, formatDistanceToNow } from 'date-fns';
import { Game, Team, TeamDocument } from '../../../core/src/public-api';

const GameCard: React.FC<{
  game: Game;
  handlePick: (team: TeamDocument) => void;
  loading: boolean;
}> = ({ game, handlePick, loading = false }) => {
  const [teamPicked, setTeamPicked] = useState<TeamDocument | null>(null);
  const [winnerPicked, setWinnerPicked] = useState<boolean | null>(null);
  const [picksLockTime, setPicksLockTime] = useState('');
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  useEffect(() => {
    setPicksLockTime(formatDistanceToNow(game.date));
    setTeamPicked(game.gamePick?.pickedTeam || null);
    if (game.gamePick === undefined || game.result === null)
      setWinnerPicked(null);
    else {
      setWinnerPicked(
        game.gamePick?.pickedTeam?.id === game.result?.winnerAfterSpread?.id
          ? true
          : false
      );
    }
  }, [JSON.stringify(game)]);

  const [present, dismiss] = useIonToast();

  const handleClick = (team: TeamDocument) => {
    const hasGameStarted = differenceInMinutes(game.date, new Date());
    if (hasGameStarted < 0) {
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: '🔒   Picks are locked. Good luck!',
        duration: 2000,
      });
      return;
    } else {
      handleTeamPicked(team);
    }
  };

  const saveMessageTimer = () => {
    setTimeout(() => {
      setShowSaveMessage(false);
    }, 2000);
  };
  const handleTeamPicked = (team: TeamDocument) => {
    setTeamPicked(team.id === teamPicked?.id ? null : team);
    handlePick(team);
    setShowSaveMessage(true);
    saveMessageTimer();
  };
  // console.log('logo', require(`../../assets/${game.homeTeam.logo}`));
  return (
    <GameCardStyled game={game} winnerPicked={winnerPicked} loading={loading}>
      {!!game.result ? (
        <GameOutcome winnerPicked={winnerPicked}>
          {winnerPicked
            ? 'Correct'
            : teamPicked === null
            ? 'No pick made'
            : 'Incorrect'}
        </GameOutcome>
      ) : showSaveMessage ? (
        <GameOutcome winnerPicked={winnerPicked} className="green">
          Pick saved
        </GameOutcome>
      ) : differenceInMinutes(game.date, new Date()) < 0 ? (
        <GameOutcome winnerPicked={winnerPicked}>
          {'Picks are locked 🔒'}
        </GameOutcome>
      ) : (
        <GameOutcome winnerPicked={winnerPicked}>
          {'Picks lock in ' + picksLockTime}
        </GameOutcome>
      )}
      <IonGrid>
        <IonRow>
          <TeamCard
            className={`${
              teamPicked && game.homeTeam.id !== teamPicked?.id ? 'greyed' : ''
            }`}
            onClick={() => !loading && handleClick(game.homeTeam)}
            picked={game.homeTeam.id === teamPicked?.id}
            gradientColors={game.homeTeam.gradientColors}
          >
            <MyTeamThumbnailCentered>
              <img
                src={`./assets/${game.homeTeam.logo}`}
                alt={'logo for ' + game.homeTeam.name}
              />
            </MyTeamThumbnailCentered>
            <h4 className="team-name">{game.homeTeam.shortName}</h4>
            <p className="ion-no-margin white">
              {game.homeTeam.rundownMetadata.record}
            </p>
          </TeamCard>
          <IonCol>
            <IonLabel>{format(game.date, 'LLL d')}</IonLabel>
            {game.result ? (
              <React.Fragment>
                <GameTime>{game.result?.score}</GameTime>
                <IonLabel>Final Score</IonLabel>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <GameTime>{format(game.date, 'h:mm')}</GameTime>
                <IonLabel>{format(game.date, 'z')}</IonLabel>
              </React.Fragment>
            )}
          </IonCol>
          <TeamCard
            className={`${
              teamPicked && game.awayTeam.id !== teamPicked?.id ? 'greyed' : ''
            }`}
            onClick={() => !loading && handleClick(game.awayTeam)}
            picked={game.awayTeam.id === teamPicked?.id}
            gradientColors={game.awayTeam.gradientColors}
          >
            <MyTeamThumbnailCentered>
              <img src={`./assets/${game.awayTeam.logo}`} alt={'logo for '} />
            </MyTeamThumbnailCentered>
            <h4 className={'team-name'}>{game.awayTeam.shortName}</h4>
            <p className="ion-no-margin white">
              {game.awayTeam.rundownMetadata.record}
            </p>
          </TeamCard>
        </IonRow>
        {game.homeTeamSpread !== (null || undefined) && (
          <IonRow className={'ion-justify-content-around ion-margin-top'}>
            <IonLabel
              className={
                (game.homeTeam.id === teamPicked?.id) === true ? 'white' : ''
              }
            >
              {game.homeTeamSpread > 0 ? '+' : ''}
              {parseFloat(game.homeTeamSpread as any).toFixed(1)}
            </IonLabel>
            <IonLabel> SPREAD </IonLabel>
            <IonLabel
              className={
                (game.awayTeam.id === teamPicked?.id) === false ? 'white' : ''
              }
            >
              {game.awayTeamSpread > 0 ? '+' : ''}
              {parseFloat(game.awayTeamSpread as any).toFixed(1)}
            </IonLabel>
          </IonRow>
        )}
      </IonGrid>
      {/* <IonCardContent>
        <MyRowSpaceAround>
          <MyCardActionIcon icon={shareOutline} />
          <MyCardActionIcon icon={expandOutline} />
          <MyCardActionIcon icon={notificationsOutline} />
        </MyRowSpaceAround>
      </IonCardContent> */}
    </GameCardStyled>
  );
};

export default GameCard;

interface GameOutcomeProps {
  winnerPicked: boolean | null;
}

const GameOutcome = styled.div<GameOutcomeProps>`
  /* display: ${(p) => (p.winnerPicked === null ? `none` : `block`)}; */
  padding: 10px 18px 6px 18px;
  background-color: ${(p) =>
    p.winnerPicked
      ? `var(--ion-color-success)`
      : p.winnerPicked === null
      ? 'none'
      : `var(--ion-color-danger)`};
  color: ${(p) =>
    p.winnerPicked != null ? `var(--ion-color-light)` : 'lightgrey'};
  position: relative;
  border-radius: 0px 0px 16px 16px;
  font-weight: 700;
  max-width: fit-content;
  margin: -20px auto 10px auto;
`;

interface GameCardProps extends React.FC {
  game: Game;
  loading: boolean;
  winnerPicked: boolean | null;
}
const GameCardStyled = styled(IonCard)<GameCardProps>`
  opacity: ${(p) => (p.game.result && p.winnerPicked === null ? `0.5` : ``)};
  transition: all 200ms ease-in-out;
  filter: ${(p) =>
    p.game.result && p.winnerPicked === null ? `grayscale(100%)` : ``};
  border: ${(p) =>
    !!p.game.result && p.winnerPicked
      ? `3px solid var(--ion-color-success)`
      : p.winnerPicked === null
      ? `3px solid rgb(28,28,29)`
      : `3px solid var(--ion-color-danger)`};
  min-width: 300px;
  max-width: 600px;
  margin-top: 8px;
  padding: 16px;
  margin: 10px 4px;
  border-radius: 12px;
`;
