export const handleModelArray = (data: any[] | any): any[] => {
  // if it's an array, we do nothing
  if (Array.isArray(data)) {
    return data;
  }
  return Object.entries(data).map(([key, value]) => {
    /**
     * if value is a boolean, that means the shape of the object is
     * { [key]: boolean } and we need to return the key to make it [key]
     */
    if (typeof value === 'boolean') {
      return key;
    }
    /**
     * if value is not boolean, that means the shape of the object is
     * { [objectId]: object } and we need to return the object to make it [object]
     */
    return value;
  });
};

/**
 * Pass in array or object and return an object i.e. Firestore Map
 *
 * @param data array or object
 * @returns object
 */
export const convertArrayToFirestoreMap = (data: any[] | any): any => {
  /**
   * if it's an array, then we want to convert the array into an object
   */
  if (Array.isArray(data)) {
    return (data as any[]).reduce((acc, el) => {
      if (!el) {
        return acc;
      }
      /**
       * if it's a primitive (string or number in this case),
       * the shape of the object should look like:
       * { [key]: boolean }
       */
      if (typeof el === 'string' || typeof el === 'number') {
        return {
          ...acc,
          [el]: true,
        };
      }
      const id = el.id || el.userId;
      return {
        ...acc,
        [id]: { ...el },
      };
    }, {});
  }
  return data;
};
