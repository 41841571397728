import { GamePick } from '.';
import { GameDocument } from '../dto/game-document';
import { ResultDocument } from '../dto/result-document';
import { TeamDocument } from '../dto/team-document';
import { FirestoreBase } from './firestore-base';

export class Game extends FirestoreBase implements GameDocument {
  homeTeam: TeamDocument;
  awayTeam: TeamDocument;
  rawGame: any;
  date: Date;
  homeTeamSpread: number;
  awayTeamSpread: number;
  result: ResultDocument | null;
  rundownId: string;
  gamePick?: GamePick;

  constructor(value: Game | GameDocument) {
    super(value);
    this.homeTeam = value.homeTeam;
    this.awayTeam = value.awayTeam;
    this.rawGame = value.rawGame;
    this.date = value.date;
    this.homeTeamSpread = value.homeTeamSpread;
    this.awayTeamSpread = value.awayTeamSpread;
    this.result = value.result || null;
    this.rundownId = value.rundownId;
  }

  build(userId?: string) {
    // remove gamePick because we don't want it in the database
    const { gamePick, ...game } = this;
    return {
      ...(game as this),
      lastUpdatedBy: userId || this.lastUpdatedBy,
      lastUpdatedOn: userId ? new Date() : this.lastUpdatedOn,
    };
  }
}
