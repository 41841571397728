import {
  IonAvatar,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { useParams } from 'react-router-dom';
import { useGetGroup, useGroup } from '../../../services/use-group';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import {
  LeaderboardDocument,
  LeaderboardMemberDocument,
} from '../../../../core/src/public-api';
import LeaderboardPage from '../../../components/leaderboard-page';

export const GroupLeaderboard = () => {
  const { groupId, leaderboardId } = useParams() as any;
  const [leaderboard, setLeaderboard] = useState<LeaderboardDocument>();
  const [members, setMembers] = useState<LeaderboardMemberDocument[]>([]);
  const { group } = useGetGroup(groupId);
  const { getSingleGroupLeaderboard } = useGroup();
  useEffect(() => {
    getSingleGroupLeaderboard(groupId, leaderboardId)
      .then(({ leaderboard, members }) => {
        setLeaderboard(leaderboard);
        setMembers(members);
      })
      .catch((error) => console.error(error));
  });
  return (
    (leaderboard && (
      <LeaderboardPage leaderboard={leaderboard} members={members} />
    )) || <div></div>
  );
};

const RankNumber = styled.h2`
  color: var(--ion-color-primary);
  margin-right: 15px;
  font-size: 28px;
  font-weight: 700;
  margin-top: 4px;
`;

const LeaderboardPoints = styled.p`
  color: green !important;
  font-weight: 500;
`;
