import {
  IonModal,
  IonPage,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router';
import ForgotPassword from './pages/forgot-password';
import Login from './pages/login';
import Register from './pages/register';
import { useRouteMatch } from 'react-router-dom';
import { useAuthPath } from './hooks/use-auth-path';

export enum AuthRoutes {
  Login = 'login',
  Register = 'register',
  ForgotPassword = 'forgot-password',
}

const AuthModal: React.FC<{
  onDismiss: (show: boolean) => void;
  showModal: boolean;
}> = ({ onDismiss, showModal }) => {
  const [activeRoute, setActiveRoute] = useState('login');
  const [title, setTitle] = useState('');

  //Looks at the activeRoute state variable and sets a title on the modal accordingly
  useEffect(() => {
    switch (activeRoute) {
      case AuthRoutes.Login:
        setTitle('Login');
        break;
      case AuthRoutes.Register:
        setTitle('Sign Up');
        break;
      case AuthRoutes.ForgotPassword:
        setTitle('ForgotPassword');
        break;
    }
  }, [activeRoute]);

  return (
    <IonModal isOpen={showModal} swipeToClose={true}>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss(false)}>
              <IonIcon icon={close}>Close</IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {activeRoute === AuthRoutes.Login && (
        <Login
          navigate={(path) => setActiveRoute(path)}
          onDismiss={() => onDismiss(false)}
        />
      )}
      {activeRoute === AuthRoutes.Register && (
        <Register
          navigate={(path) => setActiveRoute(path)}
          onDismiss={() => onDismiss(false)}
        />
      )}
      {activeRoute === AuthRoutes.ForgotPassword && (
        <ForgotPassword
          navigate={(path) => setActiveRoute(path)}
          onDismiss={() => onDismiss(false)}
        />
      )}
    </IonModal>
  );
};

export default AuthModal;

// const Auth: React.FC<{ setShowModal: (showModal: boolean) => void }> = ({
//   setShowModal,
// }) => {
//   const { path, url } = useRouteMatch();
//   return (
//     <IonRouterOutlet>
//       <Route path={`${url}/auth/login`}>
//         <Login setShowModal={setShowModal} />
//       </Route>
//       <Route path={`${url}/auth/register`}>
//         <Register setShowModal={setShowModal} />
//       </Route>
//       <Route path={`${url}/auth/forgot-password`}>
//         <ForgotPassword setShowModal={setShowModal} />
//       </Route>
//       <Route exact path={path}>
//         <Redirect to={`${url}/auth/login`} />
//       </Route>
//     </IonRouterOutlet>
//   );
// };

// export default Auth;
