import firebase from 'firebase';
import { useEffect, useState } from 'react';
import {
  LeaderboardDocument,
  LeaderboardMemberDocument,
} from '../../core/src/models';
import { firestoreData } from '../utils/firestore-data';

// future edit here for league/season
export const useLeaderboardListener = (leaderboardId: string) => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardDocument>();
  const [members, setMembers] = useState<LeaderboardMemberDocument[]>([]);
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('leaderboard')
      .doc(leaderboardId)
      .collection('members')
      .orderBy('score', 'desc')
      .onSnapshot({
        next: (snaps) => {
          const data = firestoreData(snaps);
          setMembers(data);
        },
      });
    return unsubscribe;
  }, [leaderboardId]);

  useEffect(() => {
    firebase
      .firestore()
      .collection('leaderboard')
      .doc(leaderboardId)
      .get()
      .then((board) => {
        setLeaderboard(firestoreData(board));
      })
      .catch((error) => console.error(error));
  });
  return {
    leaderboard,
    members,
  };
};

export const useLeaderboard = () => {
  const fsRef = firebase.firestore().collection('leaderboard');
  const getLeaderboards = async () => {
    try {
      const snap = await fsRef.get();
      return firestoreData(snap);
    } catch (error) {
      throw error;
    }
  };

  const getSingleLeaderboard = async (id: string) => {
    try {
      const snap = await fsRef.doc(id).get();
      return firestoreData(snap);
    } catch (error) {
      throw error;
    }
  };

  return {
    getLeaderboards,
  };
};
