import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonBackButton,
  IonButtons,
} from '@ionic/react';
import {
  LeaderboardDocument,
  LeaderboardMemberDocument,
} from '../../../core/src/models';
import styled from 'styled-components';

const LeaderboardPage: React.FC<{
  leaderboard: LeaderboardDocument;
  members: LeaderboardMemberDocument[];
}> = ({ leaderboard, members }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>NFL Leaderboard</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/profile" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* <IonRow className={'ion-justify-content-center'}>
            <div ref={element} style={{ width: 400, height: 400 }}></div>
          </IonRow> */}
        <IonList className="ion-padding-top">
          {members.map((leader, index) => (
            <IonItem key={index}>
              <RankNumber>{index + 1}</RankNumber>
              <IonLabel>
                <h2>
                  {leader.user.firstName} {leader.user.lastName}
                </h2>
                <LeaderboardPoints>
                  {Math.round(leader.score)}
                </LeaderboardPoints>
              </IonLabel>
              {/* If the user has a photo, load it. If not, load placeholder */}
              {leader.user.photoUrl ? (
                <IonAvatar slot="end">
                  <img src={leader.user.photoUrl} />
                </IonAvatar>
              ) : (
                <IonAvatar slot="end">
                  <img src={'./defaultAvatar.png'} />
                </IonAvatar>
              )}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

const RankNumber = styled.h2`
  color: var(--ion-color-primary);
  margin-right: 15px;
  font-size: 28px;
  font-weight: 700;
  margin-top: 4px;
`;

const LeaderboardPoints = styled.p`
  color: green !important;
  font-weight: 500;
`;

export default LeaderboardPage;
