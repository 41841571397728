import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Group } from '../../../core/src/public-api';

export enum Privacy {
  public = 'Public',
  private = 'Private',
}

const GroupCard: React.FC<{ group: Group }> = ({ group }) => {
  return (
    <IonCard routerLink={`/groups/${group.id}`} className="my-2">
      <IonCardHeader>
        <IonCardTitle>{group.name}</IonCardTitle>
        <IonCardSubtitle>Public</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="flex -space-x-2 overflow-hidden">
          {group.members
            ?.slice(0, 5)
            .map(
              (member) =>
                member.photoUrl && (
                  <img
                    key={member.id}
                    className="inline-block h-8 w-8 rounded-full ring-2 ring-gray-800"
                    src={member.photoUrl}
                    alt="member"
                  />
                )
            )}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default GroupCard;
