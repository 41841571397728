import styled from 'styled-components';
import { IonImg } from '@ionic/react';
import { useAuth } from '../auth/services/auth.service';
import { useHistory } from 'react-router-dom';

const GoogleSignInButton: React.FC<{
  onDismiss: () => void;
}> = ({ onDismiss }) => {
  const auth = useAuth();
  const handleSignIn = async () => {
    try {
      await auth.signInWithGoogle();
      onDismiss();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <GoogleButton
      src={'/SignInWithGoogle.png'}
      onClick={handleSignIn}
    ></GoogleButton>
  );
};

const GoogleButton = styled(IonImg)`
  margin: 12px auto;
  width: 300px;
  transition: all 150ms ease-in-out;
  :hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

export default GoogleSignInButton;
