import { FirestoreBaseDocument } from '../dto/firestore-base-document';

export class FirestoreBase implements FirestoreBaseDocument {
  id: string;
  archived: boolean;
  createdBy: string;
  createdOn: Date;
  lastUpdatedBy: string;
  lastUpdatedOn: Date;

  constructor(value: FirestoreBaseDocument | FirestoreBase) {
    this.id = value.id;
    this.archived = value.archived;
    this.createdBy = value.createdBy;
    this.createdOn = value.createdOn;
    this.lastUpdatedBy = value.lastUpdatedBy;
    this.lastUpdatedOn = value.lastUpdatedOn;
  }

  build(userId?: string) {
    return {
      ...this,
      lastUpdatedBy: userId || this.lastUpdatedBy,
      lastUpdatedOn: userId ? new Date() : this.lastUpdatedOn,
    };
  }
}
