import {
  IonPage,
  IonContent,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonCol,
  IonGrid,
  IonRow,
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  useIonToast,
} from '@ionic/react';
import { add, cog } from 'ionicons/icons';

import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Group, LeaderboardDocument } from '../../../../core/src/models';
import LeaderboardCard from '../../../components/leaderboard-card';
import { useGetGroup, useGroup } from '../../../services/use-group';
import { UserContext } from '../../../utils/context';
import AddLeaderboardModal from '../components/add-leaderboard-modal';

const ManageGroup = () => {
  const { user, authUser } = useContext(UserContext);
  const { id } = useParams() as any;
  const { group } = useGetGroup(id);
  const [leaderboards, setLeaderbaords] = useState<LeaderboardDocument[]>([]);
  const [showAddLeaderboard, setShowAddLeaderboard] = useState<boolean>(false);
  const [isMember, setIsMember] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const { getGroupLeaderboards, addUserToGroup } = useGroup();
  const history = useHistory();
  const [present, dismiss] = useIonToast();

  useEffect(() => {
    getGroupLeaderboards(id)
      .then((boards) => setLeaderbaords(boards))
      .catch((error) => {
        console.error(error);
      });
  });

  useEffect(() => {
    if (user && group?.members.find((member) => user.id === member.id)) {
      setIsMember(true);
    } else {
      setIsMember(false);
    }
  }, [JSON.stringify(user), JSON.stringify(group)]);

  const joinGroup = () => {
    if (user && group) {
      addUserToGroup({ user, group });
    }
  };

  const handleCopyLink = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    present({
      message: '✅  Link copied. Send it to a friend!',
      duration: 3000,
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="py-2">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/groups" />
          </IonButtons>
          <IonTitle>{group?.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton>
              <IonIcon icon={cog}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen id="content">
        <div className="flex flex-row items-center justify-between px-4">
          <h2 className="text-2xl">Members</h2>
          <IonIcon size="large" icon={add} color="primary"></IonIcon>
        </div>
        <div>
          <IonList>
            {group?.members?.map((member: any, index: number) => (
              <IonItem key={index}>
                <IonLabel>
                  <h2>
                    {member.firstName} {member.lastName}
                  </h2>
                  {member.admin && <p>Admin</p>}
                </IonLabel>
                {member.photoUrl ? (
                  <IonAvatar slot="start">
                    <img src={member.photoUrl} />
                  </IonAvatar>
                ) : (
                  <IonAvatar slot="start">
                    <img src={'/defaultAvatar.png'} />
                  </IonAvatar>
                )}
              </IonItem>
            ))}
          </IonList>
        </div>
        <div className="flex flex-row items-center justify-between px-4">
          <h2 className="text-2xl">Leaderboard</h2>
          <IonIcon
            size="large"
            icon={add}
            color="primary"
            onClick={() => setShowAddLeaderboard(true)}
          ></IonIcon>
        </div>
        <section id="leaderboards">
          {leaderboards.map((leaderboard, index) => (
            <LeaderboardCard
              key={index}
              leaderboard={leaderboard}
              handleClick={() =>
                history.push(`/groups/${id}/leaderboard/${leaderboard.id}`)
              }
            />
          ))}
        </section>
      </IonContent>
      {!isMember && (
        <IonButton
          expand="block"
          className="m-4 font-semibold"
          onClick={() => joinGroup()}
        >
          Join Group
        </IonButton>
      )}
      <IonButton
        expand="block"
        className="m-4 font-semibold"
        onClick={handleCopyLink}
      >
        Copy Link
      </IonButton>
      <AddLeaderboardModal
        showModal={showAddLeaderboard}
        setShowModal={(isOpen) => setShowAddLeaderboard(isOpen)}
      />
    </IonPage>
  );
};

export default ManageGroup;
