import {
  IonButton,
  IonButtons,
  IonInput,
  IonItem,
  IonLabel,
} from '@ionic/react';
import { useState, useEffect, useRef } from 'react';
import { useController, useForm } from 'react-hook-form';
import { UserDocument } from '../../../core/src/models';
import { register } from '../../serviceWorkerRegistration';
import FormInput from '../../shared/components/form-input';
import styled from 'styled-components';

const ItemInputHybrid = ({
  label,
  text,
  handleChange,
}: {
  label: string | null;
  text: string | null;
  handleChange: (value: string) => void;
}) => {
  const inputRef = useRef<any>(null);
  const {
    handleSubmit,
    control,
    formState,
    watch,
    trigger,
    getValues,
    register,
  } = useForm();
  const { isValid } = formState;
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      await trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, isTouched, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    defaultValue: text,
    name: 'value',
    control,
    rules: {
      required: {
        value: true,
        message: `${label} is required`,
      },
    },
  });
  return (
    <IonItem>
      {editing ? (
        <>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                setEditing(false);
                handleChange(getValues('value'));
              }}
              disabled={value.length === 0}
            >
              Done
            </IonButton>
          </IonButtons>
          <IonLabel position="stacked">
            <p>{label}</p>
          </IonLabel>
          <StyledIonInput
            ref={(ref) => {
              inputRef.current = ref;
            }}
            type="text"
            autocomplete="off"
            value={value}
            name="control"
            onIonChange={(e) => {
              onChange(e.detail.value!);
            }}
            onIonBlur={() => onBlur && onBlur()}
            className={'input-hybrid'}
          />
        </>
      ) : (
        <>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                setEditing(true);
                setTimeout(() => {
                  inputRef.current.setFocus();
                }, 250);
              }}
            >
              Edit
            </IonButton>
          </IonButtons>
          <IonLabel>
            <p>{label}</p>
            <h2>{text}</h2>
          </IonLabel>
        </>
      )}
    </IonItem>
  );
};

export default ItemInputHybrid;

const StyledIonInput = styled(IonInput)`
  height: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
`;
