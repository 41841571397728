import { isSameWeek } from 'date-fns';
import { GamePick } from '../models';
import { Game } from '../models/classes/game';

export const splitGamesByWeek = (schedule: Game[], picks: GamePick[] = []) => {
  const gamesArray = schedule
    .sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0))
    .reduce((acc: any, game: Game, index: number, games: Game[]) => {
      const previousGame = games[index - 1];
      const gamePick = picks.find((pick) => pick.game.id === game.id);
      game.gamePick = gamePick;
      if (
        previousGame &&
        isSameWeek(game.date, previousGame.date, { weekStartsOn: 4 })
      ) {
        acc[acc.length - 1].push(game);
      } else {
        acc = [...acc, [game]];
      }
      return acc;
    }, []);
  return gamesArray;
};
