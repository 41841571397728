import firebase from 'firebase';
import { User, UserRole, AuthMethod } from '../../../core/src/public-api';
import { generateNewFsDoc } from '../../../core/src/utils';
import { firestoreData } from '../../utils/firestore-data';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useState, useEffect } from 'react';

export const useAuth = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const login = (
    email: string,
    password: string
  ): Promise<void | firebase.auth.UserCredential> => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        console.error('unable to sign in', error);
      });
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ): Promise<void | firebase.auth.UserCredential> => {
    try {
      setFirstName(firstName);
      setLastName(lastName);
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
    } catch (error) {
      throw error;
    }
  };

  const sendResetPasswordEmail = (email: string): Promise<void> => {
    return firebase.auth().sendPasswordResetEmail(email);
  };

  const authUser = (): firebase.User | null => {
    return firebase.auth().currentUser;
  };

  async function getUserWithUsername(username: string) {
    const usersRef = firebase.firestore().collection('users');
    const query = usersRef.where('username', '==', username).limit(1);
    const userDoc = (await query.get()).docs[0];
    return userDoc;
  }

  const signInWithGoogle = async () => {
    const googleUser = await firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider());
    const user = googleUser.user && (await getUser(googleUser.user.uid));
    if (user) return user;

    const userObject = googleUser.user && (await getUser(googleUser.user.uid));
    return;
  };

  const createUser = (
    firstName: string,
    lastName: string,
    authUser: firebase.User,
    authMethod: AuthMethod
  ) => {
    const fsDoc = generateNewFsDoc(authUser.uid, authUser.uid);
    const userDoc = {
      ...fsDoc,
      firstName,
      lastName,
      email: authUser.email as string,
      role: UserRole.User,
      photoUrl: authUser.photoURL,
      authMethod,
    };
    const user = new User(userDoc);
    return firebase
      .firestore()
      .collection('user')
      .doc(user.id)
      .set(user.build());
  };

  const getUser = async (id: string) => {
    const snap = await firebase.firestore().collection('user').doc(id).get();
    return snap.exists && new User(firestoreData(snap));
  };

  const updateUserPhoto = (id: string, photoURL: string) => {
    const ref = firebase.storage().ref('/users').child(id);
    return ref
      .putString(photoURL, 'data_url')
      .then(async (snapshot: firebase.storage.UploadTaskSnapshot) => {
        try {
          const url = await ref.getDownloadURL();
          await updateUser(id, { photoUrl: url });
        } catch (error) {
          throw error;
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const updateUser = (id: string, user: Partial<User>) => {
    const userRef = firebase.firestore().collection('user').doc(id);
    return userRef.update(user);
  };

  return {
    login,
    register,
    sendResetPasswordEmail,
    authUser,
    getUserWithUsername,
    signInWithGoogle,
    createUser,
    getUser,
    firstName,
    lastName,
    logout,
    updateUser,
    updateUserPhoto,
  };
};

export const useUserData = () => {
  const [authUser] = useAuthState(firebase.auth());
  const [user, setUser] = useState<User | null>(null);
  const auth = useAuth();
  useEffect(() => {
    if (authUser) {
      const unsubscribe = firebase
        .firestore()
        .collection('user')
        .doc(authUser.uid)
        .onSnapshot({
          next: (snap) => {
            if (snap.exists) {
              const data = firestoreData(snap);
              const user = new User(data);
              setUser(user);
            } else {
              setUser(null);
            }
          },
        });
      return unsubscribe;
    } else {
      setUser(null);
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser) {
      const getUser = async () => {
        let userObject = await auth.getUser(authUser.uid);
        if (userObject) setUser(userObject);
        else {
          if (authUser.providerData[0]?.providerId === 'google.com') {
            await auth.createUser(
              authUser?.displayName?.split(' ')[0] || '',
              authUser?.displayName?.split(' ').slice(1).join(' ') || '',
              authUser as firebase.User,
              AuthMethod.Google
            );
          }
          if (authUser.providerData[0]?.providerId === 'password') {
            await auth.createUser(
              auth.firstName,
              auth.lastName,
              authUser,
              AuthMethod.Email
            );
          }
        }
      };
      getUser();
    } else {
      setUser(null);
    }
  }, [authUser]);

  return { user, authUser };
};
