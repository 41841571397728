import { IonInput, IonItem, IonLabel, IonText } from '@ionic/react';
import React, { useState } from 'react';
import { Controller, FieldError, useController } from 'react-hook-form';
import styled from 'styled-components';
import { emailValidator } from '../../../utils/validators/form-validators';

interface FormError {
  message: string;
  validationFn: Function;
  label: string;
}

const StyledIonItem = styled(IonItem)`
  border-radius: 8px;
  margin: 16px 0 8px;
`;

const StyledError = styled.div`
  margin: 0 16px 16px;
  height: 12px;
`;

const StyledIonInput = styled(IonInput)`
  border: 2px solid darkgrey;
  border-radius: 8px;
  margin: 8px 0;
  padding-left: 12px !important;

  :focus-within {
    border: 2px solid var(--ion-color-primary);
  }
`;

const FormInput = ({
  control,
  rules,
  name,
  label,
  type,
}: {
  control: any;
  rules?: any;
  name: string;
  label: string;
  type?: any;
}) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  });
  return (
    <React.Fragment>
      <IonLabel position="floating">{label}</IonLabel>
      <StyledIonInput
        ref={ref}
        type={type || 'text'}
        autocomplete="off"
        value={value}
        name="control"
        onIonChange={(e) => {
          onChange(e.detail.value!);
        }}
        onIonBlur={() => onBlur && onBlur()}
      ></StyledIonInput>
      {isDirty && invalid && (
        <StyledError>
          <IonText color="danger">{error?.message}</IonText>
        </StyledError>
      )}
    </React.Fragment>
  );
};

// export const InputController = ({
//   control,
//   name,
// }: {
//   control: any;
//   name: string;
// }) => {
//   const { field, fieldState } = useController({
//     name,
//     control,
//     rules: {
//       validate: (value) => emailValidator(value) || 'Valid email is required',
//       required: { value: true, message: 'Email is required' },
//     },
//   });
//   const fields = { ...field, ...fieldState };
//   return <FormInput {...fields} label="Email" />;
// };

export default FormInput;
