import { FirestoreBaseDocument } from '../../models';

export const generateNewFsDoc = (
  userId: string,
  id: string
): FirestoreBaseDocument => ({
  id,
  archived: false,
  createdBy: userId,
  createdOn: new Date(),
  lastUpdatedBy: userId,
  lastUpdatedOn: new Date(),
});
