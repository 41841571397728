import { createContext, SetStateAction } from 'react';
import { User } from '../../core/src/models';
import firebase from 'firebase';

interface UserContext {
  authUser: firebase.User | null | undefined;
  user: User | null;
}

export const UserContext = createContext<UserContext>({
  authUser: null,
  user: null,
});
