import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useGroup } from '../../../../services/use-group';
import FormInput from '../../../../shared/components/form-input';

const CreateGroupModal: React.FC<{
  showModal: boolean;
  setShowModal: (isOpen: boolean) => void;
}> = ({ showModal, setShowModal }) => {
  //Form stuff
  const { handleSubmit, control, formState, watch, trigger, reset } = useForm();
  const { isDirty, isValid } = formState;

  const { createGroup } = useGroup();

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      await trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const createNewGroup = async (data: any) => {
    try {
      await createGroup({ name: data.name });
      setShowModal(false);
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IonModal isOpen={showModal} cssClass="group-modal" swipeToClose={true}>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>Create a group</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(false)}>
              <IonIcon icon="close">Close</IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <form onSubmit={handleSubmit((data) => createNewGroup(data))}>
                <FormInput
                  label="Group name"
                  name="name"
                  type="text"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Group name is required',
                    },
                  }}
                />
              </form>
            </IonCol>
          </IonRow>
          <IonRow className="ion-no-padding">
            <IonCol>
              <IonButton
                expand="block"
                disabled={!isValid}
                onClick={handleSubmit((data) => createNewGroup(data))}
              >
                Create group
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default CreateGroupModal;
