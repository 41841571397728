import firebase from 'firebase';
import { useContext, useEffect, useState } from 'react';
import {
  generateNewFsDoc,
  Group,
  GroupDocument,
  LeaderboardDocument,
  User,
  Visibility,
} from '../../core/src/public-api';
import { UserContext } from '../utils/context';
import { firestoreData } from '../utils/firestore-data';
import { createId } from '../utils/firestore/generate-firestore-id';

export const useGroup = () => {
  const { user } = useContext(UserContext);
  const firestoreRef = firebase.firestore().collection('group');
  const createGroup = async ({ name }: { name: string }) => {
    try {
      const id = createId();
      const fsDoc = generateNewFsDoc(user?.id as string, id);
      const groupDoc: GroupDocument = {
        ...fsDoc,
        name,
        visibility: Visibility.Public, // private/public will be set at a later date
        members: {},
      };
      const group = new Group(groupDoc);
      console.log('group', group);
      await firestoreRef.doc(group.id).set(group.build());
      await addUserToGroup({ user: user as User, group });
    } catch (error) {
      throw error;
    }
  };

  const addUserToGroup = async ({
    user,
    group,
  }: {
    user: User;
    group: Group;
  }) => {
    try {
      group.addUser(user);
      await updateGroup({ group });
      const leaderboards = await getGroupLeaderboards(group.id);
      const member = {
        user: user.build(),
        score: 0,
      };
      for (const board of leaderboards) {
        await firestoreRef
          .doc(group.id)
          .collection('leaderboard')
          .doc(board.id)
          .collection('members')
          .doc(user.id)
          .set(member);
      }
    } catch (error) {
      throw error;
    }
  };

  const updateGroup = async ({ group }: { group: Group }) => {
    console.log('group', group);
    return firestoreRef.doc(group.id).set(group.build(user?.id));
  };

  const deleteGroup = async (group: Group) => {
    return firestoreRef.doc(group.id).delete();
  };

  const getGroups = async () => {
    try {
      const groupsSnap = await firestoreRef.get();
      const groupsDocs = firestoreData(groupsSnap);
      return groupsDocs.map((group: GroupDocument) => new Group(group));
    } catch (error) {
      throw error;
    }
  };

  const getUserGroups = async (userId: string) => {
    try {
      const groupsSnap = await firestoreRef
        .where(`members.${userId}.id`, '==', userId)
        .get();
      const groupsDocs = firestoreData(groupsSnap);
      return groupsDocs.map((group: GroupDocument) => new Group(group));
    } catch (error) {
      throw error;
    }
  };

  const getGroup = async (id: string) => {
    try {
      const groupSnap = await firestoreRef.doc(id).get();
      const groupDoc = firestoreData(groupSnap) as GroupDocument;
      return new Group(groupDoc);
    } catch (error) {
      throw error;
    }
  };

  const addLeaderboardToGroup = async (
    leaderboard: LeaderboardDocument,
    groupId: string
  ) => {
    try {
      return firestoreRef
        .doc(groupId)
        .collection('leaderboard')
        .doc(leaderboard.id)
        .set(leaderboard);
    } catch (error) {
      throw error;
    }
  };

  const getGroupLeaderboards = async (groupId: string) => {
    try {
      const snap = await firestoreRef
        .doc(groupId)
        .collection('leaderboard')
        .get();
      return firestoreData(snap);
    } catch (error) {
      throw error;
    }
  };

  const getSingleGroupLeaderboard = async (
    groupId: string,
    leaderboardId: string
  ) => {
    try {
      const ref = firestoreRef
        .doc(groupId)
        .collection('leaderboard')
        .doc(leaderboardId);
      const leaderboardSnap = await ref.get();
      const membersSnap = await ref.collection('members').get();
      return {
        leaderboard: firestoreData(leaderboardSnap),
        members: firestoreData(membersSnap),
      };
    } catch (error) {
      throw error;
    }
  };

  return {
    createGroup,
    updateGroup,
    addUserToGroup,
    getGroups,
    getGroup,
    getUserGroups,
    addLeaderboardToGroup,
    getGroupLeaderboards,
    getSingleGroupLeaderboard,
  };
};

export const useGetGroup = (id: string) => {
  const [group, setGroup] = useState<Group>();

  useEffect(() => {
    const firestoreRef = firebase.firestore().collection('group');
    const unsubscribe = firestoreRef.doc(id).onSnapshot({
      next: (snap) => {
        const data = firestoreData(snap);
        const group = new Group(data);
        setGroup(group);
      },
    });
    return unsubscribe;
  }, [id]);
  return {
    group,
  };
};

export const useGetUserGroups = () => {
  const { user } = useContext(UserContext);
  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    if (user) {
      const firestoreRef = firebase
        .firestore()
        .collection('group')
        .where(`members.${user.id}.id`, '==', user.id);
      const unsubscribe = firestoreRef.onSnapshot({
        next: (snap) => {
          const data = firestoreData(snap);
          const groups = data.map((group: GroupDocument) => new Group(group));
          setGroups(groups);
        },
      });
      return unsubscribe;
    }
  }, [JSON.stringify(user)]);
  return {
    groups,
  };
};
