import {
  IonAlert,
  IonButton,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonModal,
  IonCol,
  IonLabel,
  IonButtons,
  IonImg,
  IonItemDivider,
  IonItem,
} from '@ionic/react';

import { StyledIonButton } from '../../../components/MyComponents';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import FormInput from '../../../shared/components/form-input';

import { emailValidator } from '../../../utils/validators/form-validators';
import { useAuth } from '../../services/auth.service';
import GoogleSignInButton from '../../../components/GoogleSignIn';
import '../../../styles/utilities.css';
import { useAuthPath } from '../../hooks/use-auth-path';

const Login: React.FC<{
  navigate: (path: string) => void;
  onDismiss: () => void;
}> = ({ navigate, onDismiss }) => {
  const [showError, setShowError] = useState(false);
  const { handleSubmit, control, formState, watch, trigger } = useForm();
  const { isDirty, isValid } = formState;
  const authPath = useAuthPath();

  const auth = useAuth();

  React.useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      await trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const handleLogin = async (event: any) => {
    try {
      await auth.login(event.email, event.password);
      onDismiss();
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <IonContent>
      <div className="ion-padding ion-text-center">
        <h1 className={'branded-title'}>GameTheory</h1>
      </div>
      <IonGrid fixed className="ion-padding">
        <GoogleSignInButton onDismiss={() => onDismiss()} />
        {/* Divider  */}
        <div className="relative my-4">
          {/* <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div> */}
          <div className="relative flex justify-center">
            <span className="px-2 text-xl text-white font-extrabold">or</span>
          </div>
        </div>

        <form onSubmit={handleSubmit((data) => handleLogin(data))}>
          <FormInput
            label="Email"
            name="email"
            control={control}
            rules={{
              validate: (value: string) =>
                emailValidator(value) || 'Valid email is required',
              required: { value: true, message: 'Email is required' },
            }}
          />
          <FormInput
            label="Password"
            name="password"
            type="password"
            control={control}
            rules={{
              required: { value: true, message: 'Password is required' },
            }}
          />
          <div>
            <StyledIonButton
              fill="clear"
              className={'no-margin'}
              onClick={() => navigate('forgot-password')}
            >
              Forgot Password?
            </StyledIonButton>
          </div>
        </form>
        <IonButton
          expand="block"
          onClick={handleSubmit((data) => handleLogin(data))}
          disabled={!isValid}
          color="warning"
        >
          Login
        </IonButton>
        <IonRow className="ion-justify-content-center">
          <IonButton fill="clear" onClick={() => navigate('register')}>
            Don't have an account? Sign up
          </IonButton>
        </IonRow>
      </IonGrid>

      <IonAlert
        isOpen={showError}
        onDidDismiss={() => setShowError(false)}
        header={'Error'}
        message={'Unable to send login.  Check your info and try again'}
        buttons={['Ok']}
      />
    </IonContent>
  );
};

export default Login;
